import "./style.scss";
import {createRef, useEffect, useRef, useState} from "react";

import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber, getCountryCallingCode} from 'react-phone-number-input'
import {Smartphone} from "react-feather";


function Phone({country_code, disabled, phone, onChange, isValidPhone, validPhone, limitMaxLength}) {

    const [innerValidPhone, setInnerValidPhone] = useState(false)
    useEffect(() => {
        const elm = document.getElementsByClassName('phoneInput')[0];
        elm.getElementsByTagName('input')[0].focus();
    }, [phone])

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div id={"phone"} style={{display: 'flex', alignItems: 'center', width: 350, margin: '0 auto',
                        justifyContent: 'center', position: 'relative'}}>
                        <PhoneInput
                            disabled={disabled}
                            placeholder="Enter phone number"
                            className={"phoneInput"}
                            limitMaxLength={limitMaxLength}
                            defaultCountry={country_code.toUpperCase()}
                            countries={[country_code.toUpperCase()]}
                            addInternationalOption={false}
                            countryCallingCodeEditable={false}
                            international={true}
                            value={phone}
                            onChange={(e) => {
                                let isValid = false;
                                if (e) {
                                    var countryCallingCode = getCountryCallingCode(country_code.toUpperCase())
                                    isValid = e.indexOf(countryCallingCode) === 1
                                    if (isValid) {
                                        isValid = isValidPhoneNumber(e);
                                    } else {
                                        e = `+${countryCallingCode}`;
                                    }
                                }
                                isValidPhone(isValid);
                                onChange(e, isValid);
                            }}/>
                        {validPhone &&
                            <div id={'numberStatus'}>
                                <div className="icon icon--order-success svg">
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                        <path className="checkmark__check" fill="none"
                                              d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Phone;