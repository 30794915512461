import React, {useState} from 'react';
import SystemContext from '../contexts/system-context';


export function SystemProvider(props) {
    const [messageType, setMessageType] = useState('error');
    const [message, changeMessage] = useState('');
    const [modal, setModal] = useState({});
    const [processStages, setProcessStages] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [theme, setTheme] = useState('dark');
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState({})
    const [language, setLanguage] = useState('')
    const [webClickId, setWebClickId] = useState(null)

    // useEffect(() => {
    //     if (localStorage['blacklemon:theme'] === 'dark') {
    //         setTheme('dark');
    //     }
    // }, []);

    const setMessage = (type = 'error') => (message) => {
        changeMessage(message);
        setMessageType(type);

        setTimeout(() => {
            changeMessage('');
        }, 5000);
    };

    const showModal = (name = 'error', data = {}, onClose) => {
        setModal({
            name,
            data: {
                ...data,
                onClose: () => {
                    setModalVisible(false);
                    setTimeout(() => setModal('', {}), 300)
                    onClose && onClose();
                },
            },
        });
        setModalVisible(true)
        document.body.classList.add()
    };

    const val = {
        processStages,
        messageType,
        message,
        theme,
        modal,
        modalVisible,
        setModalVisible,
        user,
        loading,
        config,
        language,
        setLanguage,
        setConfig,
        setUser,
        webClickId,
        setWebClickId,
        setMessage: setMessage('message'),
        setError: setMessage('error'),
        setSuccess: setMessage('success'),
        setProcessStages,
        showModal,
    };

    return <SystemContext.Provider value={val} {...props} />;
}

export const useSystem = () => {
    const context = React.useContext(SystemContext);

    if (!context) {
        throw new Error('must be used with SystemProvider');
    }

    return {
        ...context,
    };
};

export default {};
