import {BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
import ScrollToTop from "./components/ScrollTop";
import {Helmet} from "react-helmet";
import Home from "./pages/Home";

function App() {
    const domainElms = window.location.host.split(".");
    const domain = window.location.host.replace(domainElms.length > 2 ? domainElms[0] + "." : "", "");
    const title = domain[0].toUpperCase() + domain.substring(1)

    return (
        <div className={'App'}>
            <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{title}</title>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content="Digital Services Reimagined"/>
                    <meta property="og:image:secure_url" content=""/>
                    <meta property="og:url" content={window.location.href}/>
                    <meta name="description" content="Digital Services Reimagined"/>
                    <meta property="og:type" content="website"/>
                    <meta name="author" content="DigitalizeHUB"/>
                </Helmet>
                <ScrollToTop>
                    <Routes>
                        <Route path={'*'} element={<Home/>} key={'Home'}/>
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </div>
    );
}

export default App;
