import React, {useEffect, useState} from "react";
import {useSystem} from "../../hooks/use-system";

const HeaderEnrichment = () => {

    const {config, setWebClickId} = useSystem();


    useEffect(() => {
        if (config) {
            if (config.service_code == "abc-world" && config.carrier_code == "mw-bahrain") {
                loadAbcWorldBatelcoBahrainFlow()
            }
        }
    })


    const loadAbcWorldBatelcoBahrainFlow = () => {

        var params = new URLSearchParams(window.location.search)
        var detected_msisdn = params.get('msisdn')
        if (detected_msisdn == null) {
            var url_list = window.location.href.split("/")
            var page_code = url_list[3].split('?')[0]

            var redirection_url = `https://mediaworldsdp.com/api/get/users.header_enrichment/?api_key=aec409e6139b14bb30ec013ac2665209&channel_id=44&redirect_url=https://www.getyourdownload.store/${page_code}`

            window.location.href = redirection_url
        }
    }


};

export default HeaderEnrichment;