import "./style.scss";

function Button({button_text, button_primary_color, button_text_color, button_action, disabled, ...props}) {

    return (
                    <button id={"Button"} disabled={disabled} style={{
                        backgroundColor: button_primary_color,
                        color: button_text_color
                    }} onClick={button_action} {...props}>
                        {button_text}
                    </button>
    )
}

export default Button;