import React, {useEffect, useState} from "react";
import callAPI from "../../api";
import {useSystem} from "../../hooks/use-system";
import DownloadGeneric from "../../templates/DownloadGeneric";
import DownloadGenericTwo from "../../templates/DownloadGenericTwo";
import DownloadGenericVideo from "../../templates/DownloadGenericVideo";
import CheckoutOne from "../../templates/CheckoutOne";

const AntiFraud = ({antiFraudProvider, pageName, msisdn, onValidationSuccess}) => {

    const {config, setWebClickId} = useSystem();


    useEffect(() => {

        if (config) {

            if (antiFraudProvider === "EMPELLO" && config.service_code == "abc-world" && config.carrier_code == "mw-bahrain") {
                loadAbcWorldBahrainFlow()
            } else if (antiFraudProvider == 'EMPELLO_STC_BAHRAIN_ABC_WORLD' && config.service_code == "abc-world" && config.carrier_code == "mw-bahrain") {
                console.log('LOADING USE EFFECT loadAbcWorldStcBahrainFlow')
                loadAbcWorldStcBahrainFlow()
            }


        }
    })


    const loadAbcWorldBahrainFlow = () => {

        var form = document.createElement('form');
        form.id = 'fraud_form'
        document.body.appendChild(form);

        const jsFiles = [
            "https://fd.sla-alacrity.com/d513e9e03227.js",
            "https://mediaworldsdp.com/assets/js/AlacrityFraud.js?v5"
        ];

        let scriptsLoaded = 0;

        jsFiles.forEach((item, index) => {
            const scriptEle = document.createElement('script');
            scriptEle.src = item;
            scriptEle.async = false;
            scriptEle.onload = () => {
                scriptsLoaded++;
                if (scriptsLoaded === jsFiles.length) {
                    const additionalScript = document.createElement('script');

                    const fraud_ids = {
                        form: 'fraud_form',
                        confirm_button: 'fraud_subscribe_btn',
                        cancel_button: 'fraud_cancel_btn'
                    };

                    additionalScript.innerHTML = `
                    const ids = ${JSON.stringify(fraud_ids)};
                    const Fraud = new AlacrityFraud("7caa7425e70b161bab7729bd9bea0ddf", 55);
                    Fraud.execute(ids)
                    `;

                    additionalScript.id = 'anti-fraud-script';
                    document.head.appendChild(additionalScript);
                    console.log(additionalScript);
                }
            };
            document.head.appendChild(scriptEle);
        });
        requestIdleCallback()
    }

    const requestIdleCallback = () => {
        var token = document.getElementById("fraud_form").elements["token"];
        if (token && token.value !== '') {
            setWebClickId(token.value);
        } else {
            setTimeout(requestIdleCallback, 100);
        }
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const loadAbcWorldStcBahrainFlow = () => {

        //loadAbcWorldStcBahrainConfigIdChecker()

        var config_id = localStorage.getItem('dh_configId')
        console.log('loadAbcWorldStcBahrainConfigIdChecker  config_id: ', config_id);

        if (config_id === 'undefined' || config_id == null) {

            setTimeout(() => {
                console.log('AFTER settimeout loadAbcWorldStcBahrainConfigIdChecker  config_id: ', config_id);
                callAPI({}, 'initializeWebClick').then((data) => {
                    if (data.status === 'SUCCESS') {
                        console.log(data)
                        const script = document.createElement('script');
                        script.text = data.payload.script;
                        document.head.appendChild(script);
                        setWebClickId(data.payload.click_id);
                    } else {
                    }
                });
            }, 2500)

        }

    }




};

export default AntiFraud;