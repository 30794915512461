import "./style.scss";

function Logo({logo_url, logo_position, logo_size, classes}) {

    const logoClasses = `${logo_position} ${logo_size} ${classes}`;

    return (
        <div className="container logo-container">
            <div className="row">
                <div className="col">
                    <img src={logo_url} alt={'Logo'} id="Logo" className={logoClasses}/>
                </div>
            </div>
        </div>
    )
}

export default Logo;
