import {useSystem} from "../../hooks/use-system";
import Logo from "../../components/Logo";

import "./style.scss"
import Flow from "../../components/Flow";
import {useState} from "react";

function CheckoutOne() {

    const {config, language} = useSystem();
    const [bottomParagraph, setBottomParagraph] = useState("");
    const [parentStep, setParentStep] = useState(0);


    return (
        <div id="main" className={'checkout-one'} style={{
            backgroundImage: `url(${config.background_image})`, //setting the background image for the page
            backgroundColor: config.background_color, //if no background image then use background color
            color: config.text_color,
            fontFamily: config.font_family,
        }}>

            <div id={"mainBox"} className={config.layout === 'BOXED' ? 'active' : ''} style={{
                backgroundColor: config.layout === 'BOXED' ? config.boxed_background_color : ''
            }}>
                <header style={{backgroundColor: config.header_background_color}}>
                    {config.logo_url && !config.logo_under_button &&
                        <Logo logo_url={config.logo_url} logo_position={config.logo_position}
                              logo_size={config.logo_size}/>
                    }
                    <div className={'headerRight'}>
                        <b>NordVPN</b>: skydda din internetåtkomst och dina anslutningar idag!
                    </div>
                </header>

                <div className={'checkoutHeader my-3'}>
                    <h1>{config?.checkout_title || `Registrera dig nu!`}</h1>
                    <p>{config?.checkout_description || `Slutför din registrering genom att följa stegen nedan.`} </p>
                </div>

                <div className={'steps'}>
                    {config?.texts[language].map((t, i) => {

                        const classes = `oneStep my-2 ${parentStep == i ? 'active' : ''}`
                        return <div className={classes}>
                            <div className={'stepNumber'} style={{
                                backgroundColor: config.button_primary_color,
                                color: config.button_text_color
                            }}>{i + 1}</div>
                            <div className={'stepTitle ms-2'}>{t.title}</div>
                        </div>
                    })}
                </div>

                <section className={'my-auto'}>
                    <Flow config={config} language={language} setBottomParagraph={setBottomParagraph}
                          setParentStep={setParentStep}/>
                </section>

                <footer className={'mt-auto mb-1'}>
                    <div className="container">
                        <div className={'row'}>
                            <div className={'col'}>
                                <div className="paragraph smaller"
                                     dangerouslySetInnerHTML={{__html: bottomParagraph}}>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <a href={config.terms_url}>{config.terms_text}</a>
                                {
                                    config.policy_url &&
                                    <>
                                        &nbsp; <span className="text-light">|</span> &nbsp;
                                        <a
                                            style={{color: config.titles_color}}
                                            href={config.policy_url}
                                            rel="noreferrer"
                                            target={'_blank'}>{config.policy_text}</a>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </footer>
                {config.bottom_text &&
                    <div id={'bottomText'} dangerouslySetInnerHTML={{__html: config.bottom_text}}></div>
                }

            </div>

        </div>
    )
}

export default CheckoutOne;
