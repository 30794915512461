import Modal from "../modal";
import {isIOS} from "react-device-detect";

function AutoSMS({texts, short_code, keyword, duration}) {

    setTimeout(() => {
        const smsCommand = isIOS ? `sms://open?addresses=${short_code};?&body=${keyword}` : `sms:${short_code};?&body=${keyword}`;
        window.location.assign(smsCommand)
    }, duration*1000)

    return (
        <Modal title={texts.title}>
            <div className={'text-center'}>
                <div className="spinner-border spinner-border-lg text-dark"><span
                    className="visually-hidden">Loading...</span></div>
            </div>
            <div className={'text-center mt-3'} dangerouslySetInnerHTML={{__html: texts.description}}></div>
        </Modal>
    );
}

export default AutoSMS;