import "./style.scss";
import {Col, Container, Row} from "react-bootstrap";
import ReactCountryFlag from "react-country-flag"
import {isMobile} from "react-device-detect";

import ratingImg from "../../assets/images/rating.png";
import LanguageSelector from "../LanguageSelector";

function DHNavbar({config, classes}) {

    const logoClasses = `${config.logo_position} ${config.logo_size} ${classes}`;

    return (
        <Container fluid>
            <Container style={{
                maxWidth: 680
            }}>
                <div className="d-flex justify-content-around">
                        <img src={config.logo_url} alt={'Logo'} id="Logo" className={logoClasses}/>
                    {config.show_rating &&
                        <div className={'d-flex align-items-center'}>
                            <img src={ratingImg} alt={'Rating'} className={'img-fluid'}/>
                        </div>
                    }
                    {config.show_country_flag &&
                        <div className={'d-flex align-items-center justify-content-end text-uppercase'}>
                            <span className={'me-2 country_code'}>{config.country_code}</span> <ReactCountryFlag
                            countryCode={config.country_code} svg
                            style={{
                                width: isMobile ? '2rem' : '3em',
                                height: isMobile ? '2rem' : '3em',
                            }}
                            title={config.country_code}/>
                        </div>
                    }
                    {config.template === 'generic-download-video' && <LanguageSelector supportedLanguages={config.supported_languages}/> }
                </div>
            </Container>
        </Container>
    )
}

export default DHNavbar;