import Logo from "../../components/Logo";
import Flow from "../../components/Flow";
import {useState} from "react";
import {useSystem} from '../../hooks/use-system';

import video from '../../assets/images/video_formats/video.png'
import tv from '../../assets/images/video_formats/tv.png'
import tv2 from '../../assets/images/video_formats/tv2.png'
import mobile from '../../assets/images/video_formats/mobile.png'
import flv from '../../assets/images/video_formats/flv.png'
import cast from '../../assets/images/video_formats/cast.png'

import './style.scss'
import DHNavbar from "../../components/DHNavbar";
import {Eye, Heart, Share, Star, ThumbsUp} from "react-feather";

function DownloadGenericVideo() {

    const {config, language} = useSystem();
    const [bottomParagraph, setBottomParagraph] = useState("");

    return (
        <div id="main" className={`download-generic-video ${config.approach.includes('VIDEO') ? 'video-approach' : ''}`}
             style={{
                 backgroundImage: `url(${config.background_image})`, //setting the background image for the page
                 backgroundColor: config.background_color, //if no background image then use background color
                 color: config.text_color,
                 fontFamily: config.font_family,
             }}>
            <div id={"mainBox"} className={config.layout === 'BOXED' ? 'active' : ''} style={{
                backgroundColor: config.layout === 'BOXED' ? config.boxed_background_color : ''
            }}>
                <header style={{backgroundColor: config.header_background_color}}>
                    {config.logo_url && !config.logo_under_button &&
                        <DHNavbar config={config}/>
                    }
                </header>

                {/* <LanguageSelector supportedLanguages={config.supported_languages}/> */}
                {config.approach === 'DEFAULT' && config.featured_image && (
                    <section className={'mx-0 featured-image text-center d-block'}>
                        <img src={config.featured_image} className={'img-fluid'}/>
                    </section>
                )}

                {config.text_above_player &&
                    <div className="mt-5">
                        <p className="m-0" dangerouslySetInnerHTML={{__html: config.text_above_player}}></p>
                    </div>
                }
                <section className={''}>
                    <Flow config={config} language={language} setBottomParagraph={setBottomParagraph}/>
                </section>
                <div className="player-icons d-flex justify-content-between align-items-center mx-auto">
                    <div>
                        <div className="d-flex align-items-center gap-2">
                            <Eye/>
                            <p className="text-muted m-0">7589</p>
                            <div className="d-flex align-items-center">
                                <Star stroke="#BABABA" fill="#BABABA"/>
                                <Star stroke="#BABABA" fill="#BABABA"/>
                                <Star stroke="#BABABA" fill="#BABABA"/>
                                <Star stroke="#BABABA" fill="#BABABA"/>
                                <Star stroke="#BABABA"/>
                            </div>
                        </div>
                    </div>
                    <div className="align-items-center d-flex gap-2">
                        <Heart/>
                        <ThumbsUp/>
                        <Share/>
                    </div>
                </div>
                <div className="file-icons d-flex gap-3 mx-auto">
                    <img className="file-icon" src={video}/>
                    <img className="file-icon" src={tv}/>
                    <img className="file-icon" src={tv2}/>
                    <img className="file-icon" src={mobile}/>
                    <img className="file-icon" src={flv}/>
                    <img className="file-icon" src={cast}/>
                </div>

                {config.logo_url && config.logo_under_button &&
                    <Logo logo_url={config.logo_url} logo_position={config.logo_position}
                          logo_size={config.logo_size} classes={'bottom'}/>
                }
                <footer className={''}>
                    <div className="container">
                        <div className={'row'}>
                            <div className={'col'}>
                                <div className="paragraph"
                                     dangerouslySetInnerHTML={{__html: bottomParagraph}}>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <a href={config.terms_url}>{config.terms_text}</a>
                                {
                                    config.policy_url &&
                                    <>
                                        &nbsp; <span className="text-light">|</span> &nbsp;
                                        <a
                                            style={{color: config.titles_color}}
                                            href={config.policy_url}
                                            rel="noreferrer"
                                            target={'_blank'}>{config.policy_text}</a>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            {config.bottom_text &&
                <div id={'bottomText'} dangerouslySetInnerHTML={{__html: config.bottom_text}}></div>
            }

        </div>
    )

}

export default DownloadGenericVideo;