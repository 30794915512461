import "./style.scss";
import {isMobile} from "react-device-detect";
import DesktopPlayer from "./DesktopPlayer";
import MobilePlayer from "./MobilePlayer";
import VideoPlayer2 from "./VideoPlayer2";

function VideoPlayer({}) {

    return isMobile ? <MobilePlayer /> : <DesktopPlayer />

}

export default VideoPlayer;