import Flow from "../../Flow";
import Modal from "../modal";
import {isIOS} from "react-device-detect";

function FlowModal({config, setBottomParagraph}) {
    return (
        <Modal>
            <Flow config={config} setBottomParagraph={setBottomParagraph} />
        </Modal>
    );
}

export default FlowModal;